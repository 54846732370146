import {
  Box,
  Container,
  Flex,
  Hide,
  Icon,
  Image,
  Show,
  StackDivider,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  VStack,
  useSteps,
} from "@chakra-ui/react";
import { AGENCIES } from "@constants";
import { PaymentPortalText } from "components/icons";
import { LookupForm } from "features/lookupForm";
import { useLookupMutation } from "features/lookupForm/lookupAPI";
import { PaymentDetailsForm } from "features/paymentDetailsForm";
import {
  usePayAchMutation,
  usePayCardMutation,
} from "features/paymentDetailsForm/paymentDetailsAPI";
import { ResultsBox } from "features/resultsBox";
import { useState } from "react";
import { BsCreditCard } from "react-icons/bs";
import { Navigate, useParams } from "react-router-dom";
import { deepRename, snakeToCamelCase } from "utils/apiUtils";

const steps = [{ title: "Account Lookup" }, { title: "Payment Details" }, { title: "Complete" }];

export default function PaymentPage() {
  const { slug } = useParams();
  const agency = AGENCIES.find((item) => item.slug === slug);

  const [userData, setUserData] = useState({});

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const activeStepText = steps[activeStep].description;

  const [lookup, { isLoading: isLookupLoading }] = useLookupMutation();
  const [payAch, { isLoading: isPayAchLoading }] = usePayAchMutation();
  const [payCard, { isLoading: isPayCardLoading }] = usePayCardMutation();

  const isLoading = isLookupLoading || isPayAchLoading || isPayCardLoading;

  const formatDate = (date) => {
    if (!date) {
      return date;
    }
    const offset = date.getTimezoneOffset();
    // get YYYY-MM-DD from ISO string
    return new Date(date.getTime() - offset * 60 * 1000).toISOString().split("T")[0];
  };

  const onLookupSubmit = async (values, formikHelpers) => {
    const result = await lookup({
      ...values,
      dob: formatDate(values.dob),
      agencySlug: slug,
    });

    if ("data" in result) {
      formikHelpers.setSubmitting(false);
      setActiveStep(activeStep + 1);
      setUserData({
        ...values,
        ...result.data,
      });
    }

    if ("error" in result) {
      let errorText = "Something went wrong. Please try again later.";
      // if error is 404, then read from specific location
      // @ts-ignore
      if (result?.error?.data?.error?.user_message) {
        // @ts-ignore
        errorText = result.error.data.error.user_message;
      }

      formikHelpers.setSubmitting(false);
      formikHelpers.setStatus(errorText);
    }
  };

  const onPaymentDetailsSubmit = async (values, formikHelpers) => {
    const action = {
      credit_card: payCard,
      ach: payAch,
    }[values.selectedPaymentMethod];

    const postProcessedValues = {
      ...values,
      agencySlug: slug,
      paymentMethod: {
        ...values.paymentMethod,
        // Convert emails to array
        emails: values.paymentMethod.emails ? [values.paymentMethod.emails] : undefined,

        // Convert card expiration date to separate fields
        cardExpMonth: new Intl.DateTimeFormat("en-US", {
          month: "2-digit",
        }).format(values.paymentMethod.expirationDate),
        cardExpYear: new Intl.DateTimeFormat("en-US", {
          year: "numeric",
        }).format(values.paymentMethod.expirationDate),
      },
    };

    const result = await action({
      ...userData,
      ...postProcessedValues,
    });

    if ("data" in result) {
      formikHelpers.setSubmitting(false);
      setActiveStep(activeStep + 1);
      setUserData(result.data);
    }

    if ("error" in result) {
      let errorText = "Something went wrong. Please try again later.";

      // if error is 404, then read from specific location
      // @ts-ignore
      if (result?.error?.data?.error?.user_message) {
        // @ts-ignore
        errorText = result.error.data.error.user_message;
      }

      formikHelpers.setSubmitting(false);
      formikHelpers.setStatus(errorText);

      if (result?.error?.data?.error?.details?.field_errors) {
        const errorObject = deepRename(
          result.error.data.error.details.field_errors,
          snakeToCamelCase,
        );
        // API returns cardExpMonth and cardExpYear, but we need expirationDate
        errorObject.expirationDate = errorObject.cardExpYear || errorObject.cardExpMonth;
        errorObject.paymentMethod = {
          ...errorObject.paymentMethod,
          emails: errorObject.paymentMethod.emails?.[0],
        };

        formikHelpers.setErrors(errorObject);
      }
    }
  };

  // Redirect to 404 page if slug is provided, but agency is not found
  if (!agency) return <Navigate to="/404" replace />;

  return (
    <VStack spacing={[4, 4, 0]} align="stretch">
      <Flex backgroundColor="blue.500" justifyContent="center" alignItems="center">
        {agency.logoUrl && (
          <Image
            position="absolute"
            left={4}
            src={agency.logoUrl}
            alt="Logo"
            height={12}
            opacity={0.75}
          />
        )}
        <Icon as={BsCreditCard} boxSize={10} />
        <Icon as={PaymentPortalText} fill="white" width="300px" height="84px" />
      </Flex>
      <Container
        maxW="4xl"
        bg="white"
        mt={[0, 0, 0]}
        pt={[0, 0, 6]}
        px={[4, 4, 8]}
        borderRadius={[0, 0, 8]}
      >
        <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
          <Flex direction="column" gap={4}>
            <Stepper size="lg" index={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Show above="sm">
                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                  </Show>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            <Hide above="sm">
              <Text>
                Step {activeStep + 1}: <b>{activeStepText}</b>
              </Text>
            </Hide>
          </Flex>

          {activeStep === 0 && <LookupForm onSubmit={onLookupSubmit} isLoading={isLoading} />}
          {activeStep === 1 && (
            <PaymentDetailsForm
              onSubmit={onPaymentDetailsSubmit}
              userData={userData}
              isLoading={isLoading}
            />
          )}
          {activeStep === 2 && <ResultsBox />}
        </VStack>
      </Container>
    </VStack>
  );
}
